<template>
  <v-card
    
    class="card-shadow border-radius-xl px-4 py-5"
    :class="$route.name == 'VrDefault' ? 'bg-gradient-default' : ''"
  >
    <div
      class="
        bg-gradient-success
        shadow-success
        border-radius-lg
        py-3
        pe-1
        mb-6
        mt-n12
      "
    >
      <div class="chart-area">
        <canvas
          :height="$route.name == 'VrDefault' ? '370' : '170'"
          :id="salesViewsID"
        >
        </canvas>
      </div>
    </div>
    <h6
      class="ms-2 mt-6 mb-0 text-h6 font-weight-bold"
      :class="$route.name == 'VrDefault' ? 'text-white' : 'text-typo'"
    >
      Ticket Verkäufe
    </h6>
  </v-card>
</template>
<script>
import Chart from "chart.js/auto";

export default {
  name: "card-daily-sales",
  data: function () {
    return {
      salesViewsID: "salesViews",
    };
  },
  computed: {
    invoices() {
      return this.$store.getters.invoices;
    },
    monthlyData() {
      var array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        this.invoices.forEach((element) => {
          if (element.state == "approved") {
            var date = new Date(element.create_time);
            var now = new Date()
            if(now.getFullYear() == date.getFullYear()){
              array[date.getMonth()] += 1;
            }
          }
        });

      return array;
    },
  },
  watch: {
    invoices(){
      new Chart(document.getElementById(this.salesViewsID).getContext("2d"), {
      type: "line",
      data: {
        labels: [
          "Jan",
          "Feb",
          "Mär",
          "Apr",
          "Mai",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Okt",
          "Nov",
          "Dez",
        ],
        datasets: [
          {
            label: "Verkäufe",
            tension: 0,
            pointRadius: 5,
            pointBackgroundColor: "rgba(255, 255, 255, .8)",
            pointBorderColor: "transparent",
            borderColor: "rgba(255, 255, 255, .8)",
            borderWidth: 4,
            backgroundColor: "transparent",
            fill: true,
            data: this.monthlyData,
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
              color: "rgba(255, 255, 255, .2)",
            },
            ticks: {
              display: true,
              color: "#f8f9fa",
              padding: 10,
              font: {
                size: 14,
                weight: 300,
                family: "Quicksand",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#f8f9fa",
              padding: 10,
              font: {
                size: 14,
                weight: 300,
                family: "Quicksand",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    });
    }
  },
  mounted() {
    this.$store.dispatch("getAllInvoices");
    // new Chart(document.getElementById(this.salesViewsID).getContext("2d"), {
    //   type: "line",
    //   data: {
    //     labels: [
    //       "Jan",
    //       "Feb",
    //       "Mär",
    //       "Apr",
    //       "Mai",
    //       "Jun",
    //       "Jul",
    //       "Aug",
    //       "Sep",
    //       "Okt",
    //       "Nov",
    //       "Dez",
    //     ],
    //     datasets: [
    //       {
    //         label: "Verkäufe",
    //         tension: 0,
    //         pointRadius: 5,
    //         pointBackgroundColor: "rgba(255, 255, 255, .8)",
    //         pointBorderColor: "transparent",
    //         borderColor: "rgba(255, 255, 255, .8)",
    //         borderWidth: 4,
    //         backgroundColor: "transparent",
    //         fill: true,
    //         data: this.monthlyData,
    //         maxBarThickness: 6,
    //       },
    //     ],
    //   },
    //   options: {
    //     responsive: true,
    //     maintainAspectRatio: false,
    //     plugins: {
    //       legend: {
    //         display: false,
    //       },
    //     },
    //     interaction: {
    //       intersect: false,
    //       mode: "index",
    //     },
    //     scales: {
    //       y: {
    //         grid: {
    //           drawBorder: false,
    //           display: true,
    //           drawOnChartArea: true,
    //           drawTicks: false,
    //           borderDash: [5, 5],
    //           color: "rgba(255, 255, 255, .2)",
    //         },
    //         ticks: {
    //           display: true,
    //           color: "#f8f9fa",
    //           padding: 10,
    //           font: {
    //             size: 14,
    //             weight: 300,
    //             family: "Quicksand",
    //             style: "normal",
    //             lineHeight: 2,
    //           },
    //         },
    //       },
    //       x: {
    //         grid: {
    //           drawBorder: false,
    //           display: false,
    //           drawOnChartArea: false,
    //           drawTicks: false,
    //           borderDash: [5, 5],
    //         },
    //         ticks: {
    //           display: true,
    //           color: "#f8f9fa",
    //           padding: 10,
    //           font: {
    //             size: 14,
    //             weight: 300,
    //             family: "Quicksand",
    //             style: "normal",
    //             lineHeight: 2,
    //           },
    //         },
    //       },
    //     },
    //   },
    // });
  },
};
</script>
