<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <!-- <v-row class="mb-12">
        <v-col md="12">
          <sales-country></sales-country>
        </v-col>
      </v-row> -->
      <v-col cols="12" class="mb-6">
        <calendar-widget></calendar-widget>
      </v-col>
      <v-row class="mb-6 mt-15">
        <v-col lg="6" class="pt-0 mb-lg-0 mb-10">
          <card-daily-sales></card-daily-sales>
        </v-col>
        <v-col lg="6" class="pt-0 mb-lg-0 mb-10">
          <card-website-views></card-website-views>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-row>
            <v-col md="6" cols="12">
              <v-card class="mb-6 card-shadow border-radius-xl py-4">
                <v-row no-gutters class="px-4">
                  <v-col sm="4">
                    <v-avatar
                      color="bg-gradient-default border-radius-xl mt-n8"
                      class="shadow-dark"
                      height="64"
                      width="64"
                    >
                      <v-icon class="material-icons-round text-white" size="24"
                        >weekend</v-icon
                      >
                    </v-avatar>
                  </v-col>
                  <v-col sm="8" class="text-end">
                    <p
                      class="text-sm mb-0 text-capitalize text-body font-weight-light"
                    >
                      Buchungen
                    </p>
                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                      {{ eventEntries }}
                    </h4>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col md="6" cols="12">
              <v-card class="mb-6 card-shadow border-radius-xl py-4">
                <v-row no-gutters class="px-4">
                  <v-col sm="4">
                    <v-avatar
                      color="bg-gradient-primary border-radius-xl mt-n8"
                      class="shadow-primary"
                      height="64"
                      width="64"
                    >
                      <v-icon class="material-icons-round text-white" size="24"
                        >leaderboard</v-icon
                      >
                    </v-avatar>
                  </v-col>
                  <v-col sm="8" class="text-end">
                    <p
                      class="text-sm mb-0 text-capitalize text-body font-weight-light"
                    >
                      Beitrags Interaktionen
                    </p>
                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                      {{ interactions }}
                    </h4>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <!-- <v-col sm="3" cols="12">
              <v-card class="mb-6 card-shadow border-radius-xl py-4">
                <v-row no-gutters class="px-4">
                  <v-col sm="4">
                    <v-avatar
                      color="bg-gradient-success border-radius-xl mt-n8"
                      class="shadow-success"
                      height="64"
                      width="64"
                    >
                      <v-icon class="material-icons-round text-white" size="24"
                        >store</v-icon
                      >
                    </v-avatar>
                  </v-col>
                  <v-col sm="8" class="text-end">
                    <p
                      class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      "
                    >
                      Einnahmen
                    </p>
                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                      34k
                    </h4>
                  </v-col>
                </v-row>
              </v-card>
            </v-col> -->

            <!-- <v-col sm="3" cols="12">
              <v-card class="mb-6 card-shadow border-radius-xl py-4">
                <v-row no-gutters class="px-4">
                  <v-col sm="4">
                    <v-avatar
                      color="bg-gradient-info border-radius-xl mt-n8"
                      class="shadow-info"
                      height="64"
                      width="64"
                    >
                      <v-icon class="material-icons-round text-white" size="24"
                        >comments</v-icon
                      >
                    </v-avatar>
                  </v-col>
                  <v-col sm="8" class="text-end">
                    <p
                      class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      "
                    >
                      Kommentare
                    </p>
                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                      91
                    </h4>
                  </v-col>
                </v-row>
                
              </v-card>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
      <p
        class="mx-3 font-weight-normal text-body"
        style="font-size: 25px"
        v-if="events.length > 0"
      >
        Veranstaltungen:
      </p>
      <v-row class="mb-6 mt-10">
        <v-col
          v-for="(item, i) in events"
          :key="item.title + i"
          lg="4"
          class="pt-0 mb-10"
        >
          <v-card
            class="card card-shadow border-radius-xl py-5 text-center"
            @click="
              $router.push('/admin/events/details/' + item._id).catch(() => {})
            "
          >
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <img
                  :src="getImage(item.img)"
                  class="img-fluid shadow border-radius-lg"
                  :alt="item.image"
                />
              </div>
              <div
                class="colored-shadow"
                v-bind:style="{
                  backgroundImage: 'url(' + getImage(item.img) + ')',
                }"
              ></div>
            </div>

            <h5 class="font-weight-normal text-typo text-h5 mt-7 mb-2 px-4">
              {{ item.title }}
            </h5>
            <p class="mb-0 text-body font-weight-light px-5">
              {{ item.text }}
            </p>
            <hr class="horizontal dark my-6" />
            <div class="d-flex text-body mx-2 px-4">
              <p class="mb-0 font-weight-normal text-body">Anmeldungen:</p>
              <i
                class="material-icons-round position-relative ms-auto text-lg me-1 my-auto"
                >person</i
              >
              <p class="text-sm my-auto font-weight-light">
                {{ item.entries.length }}
              </p>
            </div>
            <div class="d-flex mb-4 mx-2 px-4 mt-4">
              <p class="mb-0 text-body font-weight-light">
                {{ item.active ? "Event aktiv" : "Event inaktiv" }}
              </p>
              <v-switch
                disabled
                :ripple="false"
                class="d-inline-flex mt-0 pt-0 switch ms-auto"
                v-model="item.active"
                hide-details
              ></v-switch>
            </div>
            <div class="d-flex mb-4 mx-2 px-4 mt-4">
              <p class="mb-0 text-body font-weight-light">
                {{
                  item.entries_active ? "Anmeldung aktiv" : "Anmeldung inaktiv"
                }}
              </p>
              <v-switch
                disabled
                :ripple="false"
                class="d-inline-flex mt-0 pt-0 switch ms-auto"
                v-model="item.entries_active"
                hide-details
              ></v-switch>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <p
        class="mx-3 font-weight-normal text-body"
        style="font-size: 25px"
        v-if="eventNews.length > 0"
      >
        News:
      </p>
      <v-row class="mb-6 mt-10">
        <v-col
          v-for="(item, i) in eventNews"
          :key="item.title + i"
          lg="4"
          class="pt-0 mb-10"
        >
          <v-card
            class="card-shadow border-radius-xl py-5 text-center"
            @click="
              $router.push('/admin/news/details/' + item._id).catch(() => {})
            "
          >
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <img
                  :src="getImage(item.img)"
                  class="img-fluid shadow border-radius-lg"
                  :alt="item.image"
                />
              </div>
              <div
                class="colored-shadow"
                v-bind:style="{
                  backgroundImage: 'url(' + getImage(item.img) + ')',
                }"
              ></div>
            </div>
            <div class="d-flex mx-auto mt-n8">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="material-icons-round text-primary ms-auto px-5"
                    size="18"
                  >
                    refresh
                  </v-icon>
                </template>
                <span>Neu laden</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="material-icons-round text-info me-auto px-5"
                    size="18"
                  >
                    edit
                  </v-icon>
                </template>
                <span>Bearbeiten</span>
              </v-tooltip>
            </div>
            <h5 class="font-weight-normal text-typo text-h5 mt-7 mb-2 px-4">
              <a
                href="javascript:;"
                class="text-decoration-none text-default"
                >{{ item.title }}</a
              >
            </h5>
            <p class="mb-0 text-body font-weight-light px-5">
              {{ item.text }}
            </p>
            <hr class="horizontal dark my-6" />
            <div class="d-flex text-body mx-2 px-4">
              <p class="mb-0 font-weight-normal text-body">
                Beitragsinteraktionen:
              </p>
              <i
                class="material-icons-round position-relative ms-auto text-lg me-1 my-auto"
                >person</i
              >
              <p class="text-sm my-auto font-weight-light" v-if="item.views">
                {{ item.views }}
              </p>
              <p class="text-sm my-auto font-weight-light" v-else>0</p>
            </div>
            <div class="d-flex mb-4 mx-2 px-4 mt-4">
              <p class="mb-0 text-body font-weight-light">
                {{ item.active ? "Beitrag aktiv" : "Beitrag inaktiv" }}
              </p>
              <v-switch
                disabled
                :ripple="false"
                class="d-inline-flex mt-0 pt-0 switch ms-auto"
                v-model="item.active"
                hide-details
              ></v-switch>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import SalesCountry from "./Widgets/SalesCountry.vue";
import CardWebsiteViews from "./Widgets/CardWebsiteViews.vue";
import CardDailySales from "./Widgets/CardDailySales.vue";
import CalendarWidget from "./Widgets/CalendarWidget.vue";
// import CardCompletedTasks from "./Widgets/CardCompletedTasks.vue";

export default {
  name: "Dashboard",
  components: {
    // SalesCountry,
    CardWebsiteViews,
    CardDailySales,
    CalendarWidget,
    // CardCompletedTasks,
  },
  data() {
    return {
      imageCards: [
        {
          image: require("@/assets/img/product-1-min.jpeg"),
          title: "Cozy 5 Stars Apartment",
          text: "The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to 'Naviglio' where you can enjoy the main night life in Barcelona.",
          price: "899",
          location: "Barcelona, Spain",
        },
        {
          image: require("@/assets/img/product-2-min.jpeg"),
          title: "Office Studio",
          text: "The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to 'Naviglio' where you can enjoy the main night life in Barcelona.",
          price: "1.119",
          location: "London, UK",
        },
        {
          image: require("@/assets/img/product-3-min.jpeg"),
          title: "Beautiful Castle",
          text: "The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to 'Naviglio' where you can enjoy the main night life in Barcelona.",
          price: "459",
          location: "Milan, Italy",
        },
      ],
    };
  },
  mounted() {
    // if(this.events.length == 0) {
    //   this.$store.dispatch("getEventsAdmin");
    //   console.log(this.events)
    // }
    this.$store.dispatch("getEventsAdmin");
    this.$store.dispatch("getEventEntriesAdmin");
    this.$store.dispatch("getEventNewsAdmin");
  },
  computed: {
    maxSlots() {
      var max = 0;
      if (this.newEvent.sponsors.length > 0) {
        this.newEvent.sponsors.forEach((sponsor) => {
          max += parseInt(sponsor.slots);
        });
      }
      return max;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    darkMode() {
      return this.$store.getters.darkMode;
    },
    eventEntries() {
      var counter = 0;
      if (this.events) {
        this.events.forEach((event) => {
          if (event.entries) {
            counter += event.entries.length;
          }
        });
      }

      return counter;
    },
    contactFormSent() {
      return this.$store.getters.contactFormSent;
    },
    events() {
      return this.$store.getters.events;
    },
    eventNews() {
      return this.$store.getters.eventNews;
    },
    interactions() {
      var counter = 0;
      this.events.forEach((event) => {
        counter += event.views;
      });
      this.eventNews.forEach((news) => {
        counter += news.views;
      });
      return counter;
    },
    updating() {
      return this.$store.getters.updating;
    },
    authError() {
      return this.$store.getters.authError;
    },
    authMessage() {
      return this.$store.getters.authMessage;
    },
    emailResended() {
      return this.$store.getters.emailResended;
    },
    filteredEntries() {
      var preFiltered = [];
      var endFiltered = [];
      if (this.eventBuffer) {
        preFiltered = this.eventEntries.filter(
          (el) => el.event == this.eventBuffer._id
        );
        endFiltered = preFiltered.filter((el) => this.checkFilter(el));
        return endFiltered;
      }
      return [];
    },
  },
  methods: {
    getImage(link) {
      if (!link) return null;
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },
  },
};
</script>
